import { baseAPIURL } from '../constants/urls';

export async function getResident(widgetKey: string) {
  const data = await fetch(
    `${baseAPIURL}/api/widget/listings/${widgetKey}/resident_candidate`,
    {
      method: 'GET',
    }
  )
    .then((response) => response.json())
    .then((data) => data);

  return data;
}

export async function getResidents(widgetKey: string, query: string = '') {
  const data = await fetch(
    `${baseAPIURL}/api/widget/listings/${widgetKey}/residents`,
    {
      method: 'GET',
    }
  )
    .then((response) => response.json())
    .then((data) => data);

  return data?.residents;
}
