const getMetaTag = (tagName: string) => {
  const tags = document.getElementsByTagName('meta');

  for (let i = 0; i < tags.length; i++) {
    if (tags[i].getAttribute('name') === tagName) {
      return tags[i];
    }
  }

  return null;
};

const createMetaTag = (tagName: string) => {
  const tag = document.createElement('meta');

  tag.setAttribute('name', tagName);
  tag.setAttribute('id', `rg-${tagName}`);

  document.head.appendChild(tag);

  return tag;
};

export const injectViewPort = () => {
  const tagName = 'viewport';
  const tag = getMetaTag(tagName) || createMetaTag(tagName);

  tag.setAttribute(
    'content',
    'width=device-width, initial-scale=1, minimum-scale=1'
  );
};
