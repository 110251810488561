export class Emitter {
  dispatchEvent: any;
  addEventListener: any;
  removeEventListener: any;

  constructor() {
    const delegate = document.createDocumentFragment();
    [
      'addEventListener',
      'dispatchEvent',
      'removeEventListener'
    ].forEach(f =>
      this[f] = (...xs) => delegate[f](...xs)
    )
  }
}
