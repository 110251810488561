const NODE_ENV = process.env.NODE_ENV;
const DEMO_PORT = process.env.DEMO_PORT;

const environment = NODE_ENV || 'development';
const isProduction = environment === 'production';
const isDevelopment = environment === 'development';
const demoPort = DEMO_PORT;

export default {
  environment,
  demoPort,
  isProduction,
  isDevelopment,
};
