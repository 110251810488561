import $script from 'scriptjs';

export const injectScript = (url: string | string[], name: string) => {
  $script(url, name);

  return new Promise((resolve) => {
    $script.ready(name,
      () => {
        resolve(true);
      },
    );
  });
};


const isDevelopment = process.env.NODE_ENV === 'development';
const libHost = `http://localhost:${process.env.LIB_PORT}`;

export const getLibEnvURL = file => {
  return isDevelopment ? `${libHost}/${file}` : `./${file}`;
};
