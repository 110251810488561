import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

const bugsnag = Bugsnag.start({
  apiKey: process.env.BUGSNAG_KEY,
  plugins: [new BugsnagPluginReact()],
  autoTrackSessions: false,
});

const ErrorBoundaryBugsnag =
  Bugsnag.getPlugin('react').createErrorBoundary(React);

export { ErrorBoundaryBugsnag, bugsnag };
