import {
  RenderPositionMap,
  RenderMap,
  RenderStyle,
  RenderPosition,
} from './interfaces';

export const cssNamespace = `rgwdg-`; // Rentgrata Widget

export const renderStyles: RenderMap = {
  button: 'button',
  dedicated: 'dedicated',
  fixed: 'fixed',
  relative: 'relative',
};

export const renderPositions: RenderPositionMap = {
  left: 'left',
  right: 'right',
};

export const isButton = (renderAs: RenderStyle): boolean =>
  renderAs === renderStyles.button;
export const isDedicated = (renderAs: RenderStyle): boolean =>
  renderAs === renderStyles.dedicated;
export const isFixed = (renderAs: RenderStyle): boolean =>
  renderAs === renderStyles.fixed;
export const isRelative = (renderAs: RenderStyle): boolean =>
  renderAs === renderStyles.relative;

export const isPositionedLeft = (position: RenderPosition): boolean =>
  position === renderPositions.left;
export const isPositionedRight = (position: RenderPosition): boolean =>
  position === renderPositions.right;

export const getStringPositionFromConfig = (
  position: RenderPosition,
  config: any
): RenderPosition => {
  const positions = [renderPositions.left, renderPositions.right];

  if (
    isDedicated(config.renderAs || config.renderAs) ||
    !positions.includes(position)
  ) {
    return positions[0];
  }

  return position;
};

export function renderWithCompact(renderAs: RenderStyle): boolean {
  return renderStyles.fixed === renderAs;
}

function toUnsigned(value: string | number) {
  try {
    return Math.abs(parseInt(value as string, 10));
  } catch {
    return 0;
  }
}

export function getXYMargins(listing) {
  const mobile = 720;
  const isMobile = window.innerWidth <= mobile;
  const increment = (value, inc) => toUnsigned(value) + toUnsigned(inc);

  if (isMobile) {
    return [
      increment(listing.side_padding_mobile, 10),
      increment(listing.bottom_padding_mobile, 5),
    ];
  }

  return [
    increment(listing.side_padding, 20),
    increment(listing.bottom_padding, 20),
  ];
}
