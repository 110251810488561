export default function keyMirror(hash) {
  const obj = {};
  let key = null;

  for (key in hash) {
    if (hash.hasOwnProperty(key)) {
      obj[key] = key;
    }
  }

  return obj;
}
