import { IListing } from "../../@types/listing";
import { eventCategories, thirdPartyActions, thirdPartyEvents, thirdPartyLabels, thirdPartyPages } from "../constants/analytics";
import { isBetterBotIntegrated, isKnockIntegrated } from "./utils";
import { RenderStyle } from "../interfaces";
import { isDedicated } from "../styles";

interface Dimension {
  dimension: string;
  value: string;
}

export class GoogleAnalytics4 {
  listing: IListing;
  config: Record<string, any>;
  dimensions: Dimension[];

  renderAs: RenderStyle;
  useListingAnalytics: boolean;
  listingEventCategory: string;

  dataLayer: any[];

  constructor(listing: IListing, config: Record<string, any>) {
    this.listing = listing;
    this.config = config;
    this.useListingAnalytics = false;

    this.renderAs = config.renderAs || 'fixed';
    this.listingEventCategory = isDedicated(config.renderAs)
      ? eventCategories.rg_link
      : eventCategories.rg_widget;
    this.dataLayer = (window.parent as unknown as any).dataLayer || [];
    this.inject();

    this.setDimensions();
  }

  inject() {
    const gaAccountId = process.env.GA4_ANALYTICS_ID;

    (function (i, s, o, g, r, a, m) {
      i['GoogleAnalytics4Object'] = r;
      (i[r] =
        i[r] ||
        function () {
          (i[r].q = i[r].q || []).push(arguments);
        }),
        (i[r].l = 1 * (new Date() as unknown as number));
      (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
      a.async = 1;
      a.src = g;
      m.parentNode.insertBefore(a, m);
    })(
      window,
      document,
      'script',
      `https://www.googletagmanager.com/gtag/js?id=${gaAccountId}`,
      'gtag'
    );

    window.dataLayer = window.dataLayer || [];

    function gtag(){
      window.dataLayer.push(arguments);
    }

    window.gtag = gtag.bind(this);
    window.gtag('js', new Date());
    window.gtag('config', gaAccountId, { send_page_view: false });
  }

  // https://support.google.com/analytics/answer/12370404#zippy=%2Cgoogle-tag-websites
setDimensions() {
    const hasBetterBot = isBetterBotIntegrated(this.listing);
    const hasKnock = isKnockIntegrated(this.listing);

    window.gtag('set', 'user_properties', {
      'listing_id' : this.listing.id.toString() ,
      'property_manager_id' : this.listing.property_manager_id?.toString(),
      'knock_doorways' : (!hasBetterBot && hasKnock).toString(),
      'render_as' : this.config.renderAs || 'fixed' ,
      'domain' : window.location.href ,
      'active_integration' : hasBetterBot ? 'betterbot' : '',
      'prospect_reward' : this.listing.resident_reward ? this.listing.reward_amount : 0,
      'widget_source' : this.listing.partner_name || 'property',
    });
  }

  // https://developers.google.com/analytics/devguides/collection/gtagjs/events
  event = (event) => {
    try {
      const value = event.value || `${this.listing?.id}`;

      if (event.category !== 'rg_widget' && event.category !== 'rg_link') {
        window.gtag('event', event.action, {
          event_category: event.category,
          value: value,
        });
      }
      if (thirdPartyEvents[event.action]) {
        this.sendThirdPartyAnalytics(event);
      }
    } catch (error) {
      console.log(error.event);
    }
  };

  pageView = (pagePath) => {
    try {
      window.gtag('event', 'page_view', {
        page_location: pagePath,
      });

      /* ---> Max:
      There is an issue with our current strategy for sending events where
      a 'launch' event doesn't always equate to a pageview. A launch event
      should occur when a user starts an experience. Since it's possible
      to open the contact property form from the resident experience,
      we shouldn't be sending another 'launch' event when someone
      closes the contact property experience that lands them
      back on the chat with a resident experience. We need a way to 'mute'
      the launch event when a user closes the contact property form or
      goes 'back to the resident list' from the contact property success page.
    */

      if (thirdPartyPages[pagePath]) {
        if (pagePath === '/resident-list' && !isDedicated(this.renderAs)) {
          this.sendThirdPartyAnalytics({ action: 'opens_chat_with_resident' });
        } else {
          this.sendThirdPartyAnalytics({ action: pagePath });
        }
      }
    } catch (error) {
      console.log(error, pagePath);
    }
  };

  sendThirdPartyAnalytics = (analyticsAction) => {
    const analyticsEvent = {
      event: 'rg_event',
      category: this.listingEventCategory,
      action: thirdPartyActions[analyticsAction.action],
      label: thirdPartyLabels[analyticsAction.action],
      nonInteraction: thirdPartyActions[analyticsAction.action] === 'rendered',
    };

    window.gtag('event', 'rg_event', {
      event_action: analyticsEvent.action,
      event_category: analyticsEvent.category,
      event_label: analyticsEvent.label,
      non_interaction: analyticsEvent.nonInteraction,
    });

    // this is currently being pushed by UA in analytics.ts
    this.dataLayer.push(analyticsEvent);
  };

  addLayer(layer) {
    this.dataLayer.push(layer);
  }

  static mock() {
    const empty = () => null;

    return {
      event: empty,
      pageView: empty,
    };
  }
}
