import { IListing } from '../../@types/listing';
import { baseAPIURL } from '../constants/urls';

export async function getListing(widgetKey: string, query = '') {
  const listing: IListing = await fetch(
    `${baseAPIURL}/api/widget/${widgetKey}${query}`,
    {
      method: 'GET',
    }
  )
    .then((response) => response.json())
    .then((data) => data);

  return listing;
}
