export const eventCategories = {
  message_form: ('message_form'),
  resident_filter: ('resident_filter'),
  contact_property_form: ('contact_property_form'),
  widget_open: ('widget_open'),
  host: ('host'),
  origin: ('origin'),
  authentication: ('authentication'),
  conversation: ('conversation'),
  guest_card_automation: ('guest_card_automation'),
  rg_link: ('rg_messenger_link'),
  rg_widget: ('rg_widget'),
  compact: ('compact'),
};

export const thirdPartyEvents = {
  opens_knock_contact_property: true,
  opens_knock_neighborhood: true,
  opens_knock_photos: true,
  opens_knock_prices_avail: true,
  opens_knock_request_text: true,
  opens_knock_schedule_tour: true,

  opens_contact_property: true,
  opens_chat_with_resident: true,
  opens_schedule_tour: true,

  user_sends_messages_succeeds: true,
  user_opens_contact_property_form: true,

  opens_better_bot: true,
};

export const thirdPartyPages = {
  '/': true,
  '/contact-property-success': true,
  '/resident-list': true,
  '/schedule-tour': true,
};

export const thirdPartyLabels = {
  '/': 'compact widget',
  '/contact-property-success': 'contact property submitted',
  '/resident-list': 'chat with a resident',
  '/schedule-tour': 'contact property',
  opens_knock_contact_property: 'knock contact property',
  opens_knock_neighborhood: 'knock neighborhood',
  opens_knock_photos: 'knock photos',
  opens_knock_prices_avail: 'knock prices available',
  opens_knock_request_text: 'knock request text',
  opens_knock_schedule_tour: 'knock schedule tour',
  opens_contact_property: 'contact property open',
  opens_chat_with_resident: 'chat with a resident',
  opens_schedule_tour: 'contact property',
  user_sends_messages_succeeds: 'chat with a resident',
  user_opens_contact_property_form: 'contact property open',
  opens_better_bot: 'better bot',
};

export const thirdPartyActions = {
  '/': 'rendered',
  '/contact-property-success': 'completion',
  '/resident-list': 'rendered',
  '/schedule-tour': 'launch',
  opens_knock_contact_property: 'launch',
  opens_knock_neighborhood: 'launch',
  opens_knock_photos: 'launch',
  opens_knock_prices_avail: 'launch',
  opens_knock_request_text: 'launch',
  opens_knock_schedule_tour: 'launch',
  opens_contact_property: 'launch',
  opens_chat_with_resident: 'launch',
  opens_schedule_tour: 'launch',
  user_sends_messages_succeeds: 'completion',
  user_opens_contact_property_form: 'launch',
  opens_better_bot: 'launch',
};
