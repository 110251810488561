import retry from 'async/retry';
import { injectViewPort } from '../shared/dom';
import { FeatureConfig, FeatureObject, features } from '../shared/features';
import {
  getStringPositionFromConfig,
  isDedicated,
  renderWithCompact,
} from '../shared/styles';
import { getListing, getResident, getResidents } from '../shared/services';
import { errorTypes } from '../shared/constants/errorTypes';
import { GoogleAnalytics4 } from '../shared/integrations/analyticsGA4';
import { knockdoor } from '../shared/integrations/knockdoor';
import { Emitter } from './utils/emitter';
import {
  getInstanceID,
  getQueryString,
  locationChangeHandler,
  matchIgnorePath,
} from '../shared/location';
import { IgnorePathError } from '../shared/errors/IgnorePathError';

import { injectScript } from '../shared/injectScript';
import { libEnvsHost, zoidCDNURL } from '../shared/constants/urls';
import { getXYMargins } from '../shared/styles';
import { IListing } from '../@types/listing/index';
import { removeFirstResidentDuplicate } from '../shared/integrations/utils';
import { Logger } from '../shared/logger';
import env from '../app/utils/env';
import {
  CompactKeyType,
  Container,
  MessengerKeyType,
} from '../shared/interfaces';
import { DataPersist } from '../app/utils';

class Rentgrata extends Emitter {
  ready: boolean;
  loaded: boolean;
  isKnockReady: boolean;
  instanceId: string;

  triggerAttributes: Record<string, any>;
  listing: IListing | null;
  config: Record<string, any> | null;
  options: Record<string, any>;
  eventDetail: any;
  analytics: any | null;
  currentFeatureKey: string | null;
  isOpen: boolean;

  residents: Record<string, any>[];
  experiment: null;
  children: Map<CompactKeyType | MessengerKeyType, Container>;

  constructor() {
    super();

    this.ready = false;
    this.loaded = false;
    this.isKnockReady = false;

    this.triggerAttributes = {};

    this.eventDetail = null;
    this.config = null;
    this.analytics = null;
    this.currentFeatureKey = null;
    this.isOpen = false;

    this.listing = null;
    this.residents = [];

    this.options = {};
    this.children = new Map();

    injectViewPort();
  }

  onOpenEvent = () => {
    if (!this.isOpen) return;

    const event = new CustomEvent('open', {
      detail: { isOpen: true },
    });

    this.dispatchEvent(event);
  };

  toggle = () => {
    this.isOpen = !this.isOpen;

    this.children.forEach((child) => child.onToggle());
    this.onOpenEvent();
  };

  open = () => {
    this.isOpen = true;

    this.children.forEach((child) => child.onOpen());
    this.onOpenEvent();
  };

  close = () => {
    this.isOpen = false;

    this.children.forEach((child) => child.onClose());
  };

  onToggleMessenger = async (feature: FeatureObject, isOpen: boolean) => {
    this.isOpen = isOpen;

    await this.injectMessenger();

    const eventPayload = {
      ...(feature ?? ({} as FeatureConfig)),
      instanceId: this.instanceId,
      props: {
        ...(feature?.props ?? {}),
        resident: this.residents[0],
      },
    };

    this.eventDetail = eventPayload;
    this.currentFeatureKey = eventPayload.key;

    this.openInternalFeatureDispatcher(eventPayload);

    for (const child of this.children.values()) {
      child.onToggle();
    }

    this.onOpenEvent();
  };

  onToggleExpand = () => {
    this.children.forEach((child) => child.onToggle());
  };

  onTooltipDisplay = (coordinates: [number, number], label: string) => {
    this.children.forEach((child) =>
      child.onTooltipDisplay(coordinates, label)
    );
  };

  getCurrentFeatureKey = () => this.currentFeatureKey;

  unmount() {
    this.children.forEach((child) => {
      child.unmount();
    });
  }

  openWidget = async () => {
    try {
      await this.openFeature(features.CHAT_ROOM);
    } finally {
      this.analytics?.event(features.CHAT_ROOM.event);
    }
  };

  openContactProperty = async () => {
    const hasKnockContactProperty =
      this?.config?.listing?.knock_contact_property;
    try {
      if (hasKnockContactProperty) {
        this.config?.knockDoor.openContactProperties();
      } else {
        await this.openFeature(
          features.CONTACT_PROPERTY.build(this.config?.listing)
        );
      }
    } finally {
      this.analytics?.event(
        features.CONTACT_PROPERTY.buildEvent(this.config?.listing)
      );
    }
  };

  openScheduleTour = async () => {
    const canScheduleTour = this?.config?.listing?.can_schedule_tour;
    const hasKnockScheduleTour = this?.config?.listing?.knock_schedule_tour;
    const hasTour = canScheduleTour || hasKnockScheduleTour;

    if (!hasTour) return;

    try {
      if (isDedicated(this.config?.renderAs) && hasKnockScheduleTour) {
        return knockdoor.openScheduleTour();
      }

      await this.openFeature(
        features.SCHEDULE_TOUR.build(this.config?.listing)
      );
    } finally {
      this.analytics?.event(
        features.SCHEDULE_TOUR.buildEvent(this.config?.listing)
      );
    }
  };

  openCreateAccount = async () => {
    const eventPayload = Object.assign({}, features.CREATE_ACCOUNT, {
      instanceId: this.instanceId,
      props: {
        ...features.CREATE_ACCOUNT.props,
        resident: this.residents[0],
      },
    });

    this.eventDetail = eventPayload;
    this.currentFeatureKey = eventPayload.key;
    this.openInternalFeatureDispatcher(eventPayload);
    this.open();
  };

  openFeature = async (feature: FeatureConfig) => {
    await this.injectMessenger();

    if (this.isOpen && feature.key === this.currentFeatureKey) return;

    this.currentFeatureKey = feature.key;

    this.openInternalFeatureDispatcher(feature);
    this.open();
  };

  getIsOpen = () => this.isOpen;

  getStore() {
    return {
      saveCookie: DataPersist.saveCookie,
      getCookie: DataPersist.getCookie,
      getStringCookie: DataPersist.getStringCookie,
      removeCookie: DataPersist.removeCookie,
      save: DataPersist.save,
      get: DataPersist.get,
      remove: DataPersist.remove,
      saveLocalStorage: DataPersist.saveLocalStorage,
      getLocalStorage: DataPersist.getLocalStorage,
      removeLocalStorage: DataPersist.removeLocalStorage,
    };
  }

  getExperiment = () => {
    return {
      experiment: null,
    };
  };

  async preRender() {
    const query = getQueryString({
      dedicated: isDedicated(this.config.renderAs),
    });

    this.listing = await getListing(this.config.widget_key, query);
    this.analytics = new GoogleAnalytics4(this.listing, this.config);

    this.residents = removeFirstResidentDuplicate([
      await getResident(this.config.widget_key),
      ...(await getResidents(this.config.widget_key)),
    ]);

    if (matchIgnorePath(this.listing.ignore_url_paths)) {
      throw new IgnorePathError(`${window.location.pathname}`);
    }

    if (isDedicated(this.config.renderAs)) {
      this.isOpen = true;
    }
  }

  onShow = () => {
    this.children.forEach((child) => child.onShow());
  };

  onHide = () => {
    this.children.forEach((child) => child.onHide());
  };

  async postRender() {
    locationChangeHandler(this.listing.ignore_url_paths, {
      onShow: this.onShow,
      onHide: this.onHide,
    });

    await this.injectKnockDoor();
  }

  screen = () => {
    return {
      isMobile: window.innerWidth <= 720,
      isTablet: window.innerWidth <= 1024 && window.innerWidth > 720,
      isDesktop: window.innerWidth > 1024,
    };
  };

  integrations = () => {
    return {
      knock: this.knockDoor(),
    };
  };

  onIntegration = (integration: string, service: string) => {
    const serviceFn = this.integrations()[integration]?.[service];

    if (serviceFn) serviceFn();
  };

  knockDoor() {
    return {
      photos: knockdoor.openPhotos,
      requestText: knockdoor.openTextWithUs,
      neighborhood: knockdoor.openNeighborhood,
      availability: knockdoor.openAvailabilities,
      schedule: knockdoor.openScheduleTour,
      contact: knockdoor.openContactProperties,
    };
  }

  async injectCompact() {
    const fileKey: CompactKeyType = 'compact';
    const fileNameExtension = `rg-${fileKey}.min.js`;
    const url = `${libEnvsHost[env.environment]}/${fileNameExtension}`;

    await injectScript(url, `rg-${fileKey}`);

    const Component: Container = window.RGCompact;

    if (!Component.render) return; // May need to report error. This should rarely happen.

    this.children.set(fileKey, Component);

    await Component.render(this.config);

    this.addEventListener('ready', () => {
      this.injectMessenger();
    });
  }

  async injectMessenger() {
    return new Promise(async (resolve, reject) => {
      const url = `${libEnvsHost[env.environment]}/rg-messenger.min.js`;

      try {
        if (window.RGMessenger) {
          return resolve(true);
        }

        await injectScript(url, 'rg-messenger');

        this.children.set('messenger', window.RGMessenger);

        await window.RGMessenger.render(this.config);
        resolve(true);
      } catch (error) {
        console.log(error);
        reject(false);
      }
    });
  }

  async injectKnockDoor() {
    if (knockdoor.canInject(this.listing)) {
      await injectScript(knockdoor.url, 'rg-knock');

      window.knockDoorway.init(
        this.listing.knock_init_key,
        'community',
        this.listing.knock_community_id
      );

      window.knockDoorway.inject();
      this.watchKnock();
    }
  }

  watchKnock() {
    const handler = (callback) => {
      const isReady = typeof window.knockDoorway?.openContact === 'function';
      const error = !isReady ? new Error('knockDoorway is not ready') : null;

      callback(error, isReady);
    };

    retry({ times: 10, interval: 500 }, handler, (error, result) => {
      this.isKnockReady = result;

      if (!result) {
        window.knockDoorway.inject();
        this.watchKnock();
      }

      knockdoor.overrideKnockDoorStyles(this.listing?.hide_knock_widget);
    });
  }

  getIsKnockReady = () => this.isKnockReady;

  onReadyDispatcher = () => {
    this.ready = true;
    const event = new CustomEvent('ready', {
      detail: { ready: this.ready },
    });

    this.dispatchEvent(event);
    Logger.info('ready');
  };

  openInternalFeatureDispatcher = (feature: FeatureConfig) => {
    this.eventDetail = {
      route: feature.route,
      key: feature.key,
      props: feature.props,
      instanceId: this.instanceId,
    };

    const event = new CustomEvent('feature', {
      bubbles: true,
      cancelable: true,
      detail: this.eventDetail,
    });

    this.dispatchEvent(event);
  };

  getEvent = () => this.eventDetail;

  onLoadedDispatcher = () => {
    this.loaded = true;
    const event = new CustomEvent('loaded', {
      detail: { loaded: this.loaded },
    });

    this.dispatchEvent(event);
    Logger.info('loaded');
  };

  onErrorDispatcher = (error) => {
    const event = new CustomEvent('error', {
      detail: { error },
    });

    this.dispatchEvent(event);
    Logger.error();
  };

  onDispatchTriggerAttributes = (attributes) => {
    this.triggerAttributes = attributes;

    const event = new CustomEvent('trigger', {
      detail: this.triggerAttributes,
    });

    this.dispatchEvent(event);
  };

  getAnalytics() {
    return {
      event: this.analytics.event,
      pageView: this.analytics.pageView,
    };
  }

  async render(config) {
    this.onLoadedDispatcher();
    try {
      this.config = Object.assign({}, config, {
        renderAs: config.render_as || 'fixed',
      });

      this.instanceId = getInstanceID(config.render_as);

      await this.preRender();

      const [marginX, marginY] = getXYMargins(this.listing);
      await injectScript(zoidCDNURL, 'bundle');

      this.config = {
        marginX,
        marginY,
        eventDetail: this.eventDetail,
        bubble: { count: 3 },
        residents: this.residents,
        listing: this.listing,
        position: getStringPositionFromConfig(this.listing.position, config),
        ignoreURLPaths: this.listing.ignore_url_paths,
        widgetKey: config.widget_key,
        renderAs: config.render_as || 'fixed',
        height: window.innerHeight,
        width: window.innerWidth,
        screen: this.screen(),
        store: this.getStore(),
        knockDoor: this.knockDoor(),
        onIntegration: this.onIntegration,
        betterBot: { openBetterBot: () => null },
        analytics: this.getAnalytics(),
        getIsOpen: this.getIsOpen,
        getIsKnockReady: this.getIsKnockReady,
        onReadyDispatcher: this.onReadyDispatcher,
        onToggleMessenger: this.onToggleMessenger,
        getCurrentFeatureKey: this.getCurrentFeatureKey,
        onTooltipDisplay: this.onTooltipDisplay,
        onDispatchTriggerAttributes: this.onDispatchTriggerAttributes,
        addEventListener: this.addEventListener.bind(this),
        getEvent: this.getEvent,
        getParentURL: () => window.location.href,
        openCreateAccount: this.openCreateAccount,
        getExperiment: this.getExperiment,
        instanceId: this.instanceId,
      };

      if (renderWithCompact(this.config.renderAs)) {
        this.injectCompact();
      } else {
        this.injectMessenger();
      }

      this.postRender();
    } catch (error) {
      if (error.name === errorTypes.IgnorePathError) {
        return Logger.info('ignored path');
      }

      this.onErrorDispatcher(error);

      if (env.isProduction) {
        // return bugsnag.notify({
        //   error,
        // });
      }

      console.error(error);
    } finally {
    }
  }

  addAccessibilityAttributes(elementId, listing) {
    const { resident_reward, reward_amount } = listing;
    const element = document.querySelector(`#${elementId}`);

    if (!element) return;

    const reward = resident_reward
      ? `, and make $${reward_amount} if you sign a lease and move in.`
      : '.';

    element.setAttribute('role', 'dialog');
    element.setAttribute(
      'aria-label',
      `Click me to message a current resident to ask them about this property${reward}`
    );
  }
}

const rentgrata = {
  Widget: new Rentgrata(),
  version: 2,
};

if (typeof window !== 'undefined') {
  window.rentgrata = rentgrata;
}
