import { errorTypes } from "../constants/errorTypes";

export class IgnorePathError extends Error {
  name: string;

  constructor(message) {
    super(message)

    this.name = errorTypes.IgnorePathError;
  }
}
