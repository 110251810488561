import { IListing } from '../@types/listing';
import { modalIDs } from './constants/modalIDs';

type FeatureKey =
  | 'CHAT_WITH_RESIDENT'
  | 'CONTACT_PROPERTY'
  | 'SCHEDULE_TOUR'
  | 'CREATE_ACCOUNT'
  | 'CHAT_ROOM'
  | 'MORE';

type FeatureRoute = {
  url: string;
  params?: Record<string, string | number>;
};

type FeatureEvent = {
  action: string;
  category: string;
  value: string;
};

export type FeatureObject = {
  key: FeatureKey;
  route: FeatureRoute;
  event: FeatureEvent;
  props?: Record<string, any>;
  build: (listing: any) => FeatureObject;
  buildEvent: (listing: any) => FeatureEvent;
};

export type Features = Record<FeatureKey, FeatureObject>;

export default {
  TAG: 'rentgrata-widget',
};

export type FeatureConfig = Omit<FeatureObject, 'build' | 'buildEvent'>;

export const features: Features = {
  CHAT_WITH_RESIDENT: {
    key: 'CHAT_WITH_RESIDENT',
    route: {
      url: '/',
    },
    event: {
      action: 'opens_chat_with_resident',
      category: 'widget_open',
      value: 'opened_via_button',
    },
    build: () => features.CHAT_WITH_RESIDENT,
    buildEvent: () => features.CHAT_WITH_RESIDENT.event,
  },
  CONTACT_PROPERTY: {
    key: 'CONTACT_PROPERTY',
    route: {
      url: '/Modals',
      params: {
        id: modalIDs.CONTACT_PROPERTY_MODAL,
      },
    },
    event: {
      action: 'opens_contact_property',
      category: 'widget_open',
      value: 'opened_via_button',
    },
    build(listing: IListing) {
      if (listing?.knock_contact_property) {
        this.key = 'knock_contact_property';
      }

      return features.CONTACT_PROPERTY;
    },
    buildEvent(listing: IListing) {
      if (listing?.knock_contact_property) {
        this.event.action = 'opens_knock_contact_property';
      }

      return features.CONTACT_PROPERTY.event;
    },
  },
  SCHEDULE_TOUR: {
    key: 'SCHEDULE_TOUR',
    route: {
      url: '/Modals',
      params: {
        id: modalIDs.SCHEDULE_A_TOUR,
      },
    },
    event: {
      action: 'opens_schedule_tour',
      category: 'widget_open',
      value: 'opened_via_button',
    },
    build(listing: IListing) {
      if (listing?.knock_schedule_tour) {
        this.key = 'knock_schedule_tour';
      }

      return features.SCHEDULE_TOUR;
    },
    buildEvent(listing: IListing) {
      if (listing.knock_schedule_tour) {
        this.event.action = 'opens_knock_schedule_tour';
      }

      return features.SCHEDULE_TOUR.event;
    },
  },
  CREATE_ACCOUNT: {
    key: 'CREATE_ACCOUNT',
    route: {
      url: '/Modals',
      params: {
        id: 'NEW_USER_MODAL',
      },
    },
    event: {
      action: '',
      category: '',
      value: '',
    },
    build: () => features.CREATE_ACCOUNT,
    buildEvent: () => features.CREATE_ACCOUNT.event,
  },
  CHAT_ROOM: {
    key: 'CHAT_ROOM',
    route: {
      url: '/chat-room',
    },
    event: {
      action: '',
      category: '',
      value: '',
    },
    build: () => features.CHAT_ROOM,
    buildEvent: () => features.CHAT_ROOM.event,
  },
  MORE: {
    key: 'MORE',
    route: {
      url: '/Modals',
      params: {
        id: modalIDs.MORE_FEATURES_MODAL,
      },
    },
    event: {
      action: '',
      category: '',
      value: '',
    },
    build: () => features.MORE,
    buildEvent: () => features.CHAT_ROOM.event,
  },
};
