import Cookie from 'js-cookie';
import { isFireFox, isIE } from './utilities';

export default class DataPersist {
  static saveCookie(key, value) {
    try {
      if (!key || !value) return;

      return Cookie.set(key, JSON.stringify(value));
    } catch (error) {
      return window.xprops.store.saveCookie(key, value);
    }
  }

  static getCookie(key) {
    try {
      if (!key) return;

      const data = Cookie.get(key);

      return data && JSON.parse(data);
    } catch (error) {
      return window.xprops.store.getCookie(key).value;
    }
  }

  static getStringCookie(key) {
    try {
      if (!key) return;

      const data = Cookie.get(key);

      return data;
    } catch (error) {
      // xprops.store.getCookie is not an available method for now...
      // return window.xprops.store.getCookie(key).value;

      return;
    }
  }

  static removeCookie(key) {
    try {
      if (!key) return;

      Cookie.remove(key);
    } catch (error) {
      return window.xprops.store.removeCookie(key);
    }
  }

  static save(key, value) {
    try {
      if (!key || !value) return;

      if (window.sessionStorage && !isFireFox() && !isIE()) {
        return window.sessionStorage.setItem(key, JSON.stringify(value));
      }

      return DataPersist.saveCookie(key, value);
    } catch (error) {
      return window.xprops.store.save(key, value);
    }
  }

  static get(key) {
    try {
      if (!key) return;

      if (window.sessionStorage && !isFireFox() && !isIE()) {
        const data = window.sessionStorage.getItem(key);

        return data && JSON.parse(data);
      }

      return DataPersist.getCookie(key);
    } catch (error) {
      return window.xprops.store.get(key).value;
    }
  }

  static remove(key) {
    try {
      if (!key) return;

      if (window.sessionStorage && !isFireFox() && !isIE()) {
        return window.sessionStorage.removeItem(key);
      }

      return DataPersist.removeCookie(key);
    } catch (error) {
      return window.xprops.store.removeData(key);
    }
  }

  static saveLocalStorage(key, value) {
    try {
      if (!key || !value) return;

      if (window.localStorage && !isFireFox() && !isIE()) {
        return window.localStorage.setItem(key, JSON.stringify(value));
      }

      return DataPersist.save(key, value);
    } catch (error) {
      return window.xprops.store.saveCookie(key, value, { expires: Infinity });
    }
  }

  static getLocalStorage(key) {
    try {
      if (!key) return;

      if (window.localStorage && !isFireFox() && !isIE()) {
        const data = window.localStorage.getItem(key);

        return data && JSON.parse(data);
      }

      return DataPersist.get(key);
    } catch (error) {
      return window.xprops.store.getCookie(key).then(value => value);
    }
  }

  static removeLocalStorage(key) {
    try {
      if (!key) return;

      if (window.localStorage && !isFireFox() && !isIE()) {
        return window.localStorage.removeItem(key);
      }

      return DataPersist.remove(key);
    } catch (error) {
      return window.xprops.store.removeCookie(key);
    }
  }
}
