export function matchIgnorePath(patterns: string[] = []) {
  if (!patterns || !Array.isArray(patterns)) return false;

  return patterns.some((pattern) => {
    pattern = pattern.replace(/\s+/g, '');

    if (pattern === '/') {
      return pattern === window.location.pathname;
    }

    const pathMatch = new RegExp(pattern).test(window.location.pathname);
    const hashMatch = new RegExp(pattern).test(window.location.hash);

    return pathMatch || hashMatch;
  });
}

function shouldHideWidget(patterns, handlers) {
  if (matchIgnorePath(patterns)) {
    return handlers.onHide();
  }

  return handlers.onShow();
}

function pathChangeHandler(patterns, handlers) {
  window.addEventListener('popstate', () => {
    shouldHideWidget(patterns, handlers);
  });
}

function onHistoryPushStateChange(patterns, handlers) {
  const orgPushState = window.history.pushState;

  window.history.pushState = function() {
    orgPushState.apply(this, arguments);

    shouldHideWidget(patterns, handlers);
  };
}

export function locationChangeHandler(patterns, handlers) {
  shouldHideWidget(patterns, handlers);
  pathChangeHandler(patterns, handlers);
  onHistoryPushStateChange(patterns, handlers);
}

export function getInstanceID(renderAs: string) {
  const randomId = (new Date()).getTime().toString(36) + Math.random().toString(36).slice(2);
  
  return `${renderAs}-${randomId}`;
}

export const getQueryString = (filters = {}) => {
  return Object.entries(filters).reduce((query, filter, index) => {
    query += `${index !== 0 ? '&' : ''}${filter[0]}=${filter[1]}`;

    return query;
  }, '?');
};
